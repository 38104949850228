
import {Button, Checkbox, Grid, Icon, Input, Label, Select, TextArea} from "semantic-ui-react";
import React, {useCallback, useEffect, useMemo, useState} from "react";
import {BuilderContext, registerWidgetType} from "../WidgetRegistry";
import {
  DateFinancialValueTypes,
  FinancialValueType,
  FinancialValueTypes
} from "../../../ps-models";
import {formattedValueAsNumber, formatValueWithValueType} from "../../../ps-models/formatting";
import {
  getConfig,
  getGlobalContext,
  getStore,
  useOnConfigChange,
  useOnStoreReady,
  useUpdateOnGlobalContextChange
} from "./commons";
import {StrictButtonProps} from "semantic-ui-react/dist/commonjs/elements/Button/Button";
import {ValueType} from "../../../ps-models/line-items";
import {SingleDatePicker} from "../../../lineitems-store/DateRange";
import {LineItemsStore} from "../../../ps-models/lineitems-store";

interface ConfigProps {
  label: string,
  type: FinancialValueType,
  addToStore?: boolean,
}

registerWidgetType({
    typeId: 'Input',
    metadata: {
      name: 'Simple Input Widget',
      description: '',
      icon: 'terminal',
    },
    defaultConfig: {
      label: 'Param',
      type: "number",
      addToStore: false,
    },
  renderConfig: (config: any, context: BuilderContext, setConfig: (config: string) => void) => {
    return <WidgetConfig
      config={config} context={context} onConfigChange={setConfig}/>
  },
    render: (config: any, context: BuilderContext, setOutput) => {
      return <WidgetWithCompare context={context} setOutput={setOutput}/>
    }
  }
)

export function WidgetWithCompare({context, setOutput}: {context: BuilderContext, setOutput:(key: string, value: any) => void }) {
  useOnStoreReady(context);
  useUpdateOnGlobalContextChange(context);

  const storeToCompareWith = context.appContext.getLastStoreToCompareWith();
  const mainStore = context.appContext.getStore();
  if(storeToCompareWith){
    return <Grid style={{width: '100%'}}>
      <Grid.Row columns={2}>
        <Grid.Column>
          <InputWidget context={{...context, store: mainStore}} setOutput={setOutput}/>
        </Grid.Column>
        <Grid.Column style={{border: '2px dotted #FF9800'}}>
          <InputWidget context={{...context, store: storeToCompareWith, isRenderingComparisonStore: true}} setOutput={setOutput}/>
        </Grid.Column>
      </Grid.Row>
    </Grid>
  }

  return <InputWidget
      context={{...context, store: mainStore}} setOutput={setOutput}/>
}


function WidgetConfig({config, context, onConfigChange}:
                                      { config: ConfigProps, context: BuilderContext, onConfigChange: (config: any) => void }) {

  let [localConfig, setLocalConfig] = React.useState(config);

  const handleApply = () => {
    onConfigChange(localConfig);
  }

  return <>
    Label: <Input value={localConfig.label}

                  onChange={(e, data) => {
      setLocalConfig({...localConfig, label: data.value as string})
    } }/>
    Type: <Select options={FinancialValueTypes.map((vt)=>({value: vt, text: vt, key: vt}))}
                  defaultValue={localConfig.type ?? "number"}
                  onChange={(e, data) => {
                    setLocalConfig({...localConfig, type: data.value as FinancialValueType})
                  } }/>
    <Checkbox toggle checked={localConfig?.addToStore}
              onChange={(e, data) => {
                if(!!data.checked === false){
                  // @TODO
                // Should remove the li from store...
                }
                setLocalConfig({...localConfig, addToStore: !!data.checked})
              }}
              label={"Add to store"} />
    <Button onClick={handleApply}>Apply</Button>
  </>

}

function InputWidget({setOutput, context}: {
                                   context: BuilderContext,
                                   setOutput: (key: string, value: any) => void
}){
  useOnConfigChange<ConfigProps>(context);
  let config = getConfig(context);
  const globalContext = getGlobalContext(context);
  let store = getStore(context);

  let initialValue = context?.params?.value;

  if(context.isRenderingComparisonStore && config.label){
    if(globalContext.compareWithParamsMap){
      let val = globalContext.compareWithParamsMap[config.label] ?? 0;
      initialValue = val;
    }
  }
  if(!context.isRenderingComparisonStore && config.label){
    if(globalContext.paramsMap){
      let val = globalContext.paramsMap[config.label];
      initialValue = val;
    }
  }

  const onSave = useCallback((value:string)=>{
    let rawValue = getRawValue(value, config.type);
    console.info("setting the RAW VALUE to", rawValue, typeof rawValue, config.type, value, typeof value);
    if(!context.isRenderingComparisonStore){
      console.info("SETTING THE OUTPUT", rawValue)
      setOutput('value', rawValue);
      if(config.addToStore){
        context.appContext.setParameter(config.label, rawValue, config.type);
      }
    }
  }, [context,config.type])

  if(config.addToStore && initialValue === undefined){
    initialValue = 0;
    if(DateFinancialValueTypes.includes(config.type as any)){
      const currentDate = new Date();
      const beginningOfTodayInMs = currentDate.setHours(0,0,0,0);
      initialValue = (beginningOfTodayInMs > store.timeIndex.startDate.getTime() && beginningOfTodayInMs < store.timeIndex.endDate.getTime()) ? beginningOfTodayInMs : store.timeIndex.startDate.getTime()
    }
  }

  return <Widget
      initialValue={initialValue}
      label={config.label}
      inputValueType={config.type}
      onSave={onSave}
      readOnly={context.isRenderingComparisonStore ?? context.params.readOnly}
      store={store}
      />
}

function Widget({initialValue, inputValueType, label, onSave, readOnly, store}: {
  initialValue: ValueType,
  label: string;
  inputValueType: FinancialValueType;
  onSave: (formattedValue: string) => void,
  readOnly: boolean,
  store: LineItemsStore
}) {
  let [formattedValue, setFormattedValue] = useState<string>(formatValueWithValueType(initialValue, inputValueType, -1));

  const handleValueFinalization = ()=>{
    onSave(formattedValue)
  }

  useEffect(()=>{
    console.info("Here with initialValue", initialValue, formatValueWithValueType(initialValue, inputValueType, -1))
    if(initialValue !==undefined){
      setFormattedValue(formatValueWithValueType(initialValue, inputValueType, -1))
      onSave(formatValueWithValueType(initialValue, inputValueType, -1));
    }
  }, [initialValue])
  if(inputValueType === "string") {
    return <>
      <label>{label}</label>
      <TextArea
        key={`${label}${readOnly}`}
        style={{width: '100%'}}
        defaultValue={formattedValue}
        value={formattedValue}
        readOnly={readOnly}
        onChange={(e, {value}) => {
          console.info("setting the output to", value, typeof value);
          setFormattedValue(formatValueWithValueType(value as string, inputValueType, -1))
        }}
        minHeight={2}
    ></TextArea>
      <AcceptButton onClick={handleValueFinalization} disabled={readOnly} />
    </>
  } else if(DateFinancialValueTypes.includes(inputValueType as any)) {
    let initialStartDate = new Date(getRawValue(formattedValue, inputValueType));
    return <div style={{display: "flex", alignItems: "center", width: "100%", gap:0}}>
      <Label size={"large"}>{label}</Label>
      <div>
        <SingleDatePicker
            initialStartDate={initialStartDate}
            minDate={store.timeIndex.startDate}
            maxDate={store.timeIndex.endDate}
            onSelect={(selectedDate)=>{
              let numericValue = selectedDate.getTime();
              setFormattedValue(formatValueWithValueType(numericValue, inputValueType, -1))
            }}
            useUTC
        />
      </div>
      <AcceptButton onClick={handleValueFinalization} disabled={readOnly} />
    </div>
  } else {
    return <Input
      key={`${label}${readOnly}`}
      label={label}
      defaultValue={formattedValue}
      value={formattedValue}
      readOnly={readOnly}
      onChange={(e, data) => {
        let numericValue = formattedValueAsNumber(data.value, inputValueType)
        console.info("setting the FORMATTED VALUE to", numericValue, typeof numericValue, inputValueType, data.value, typeof data.value);
        setFormattedValue(formatValueWithValueType(numericValue, inputValueType, -1))
      }}
        action={<AcceptButton onClick={handleValueFinalization} disabled={readOnly} />}
    />
  }
}


function AcceptButton({onClick, disabled}: {onClick: StrictButtonProps['onClick'], disabled: boolean}){
  return <Button icon onClick={onClick} disabled={disabled}>
    <Icon color={'green'} name='check' />
  </Button>
}

const getRawValue = (value: string, valueType: FinancialValueType)=>{
  return valueType === "string" ? (value ?? ""): formattedValueAsNumber(value, valueType)
}