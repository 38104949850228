import {
  DataGridProProps,
  GRID_DETAIL_PANEL_TOGGLE_FIELD,
  GRID_TREE_DATA_GROUPING_FIELD,
  GridColDef,
  GridRowParams, GridToolbarColumnsButton, GridToolbarContainer, GridToolbarDensitySelector, GridToolbarExport, GridToolbarQuickFilter
} from "@mui/x-data-grid-pro";
import Box from "@mui/material/Box"
import {dissoc, empty, filter, fromPairs, indexBy, keys, map, mapObjIndexed, values} from "ramda";
import {
  QueryResult,
  LineItemsStore,
  ResultCell,
  TimeColumn, ROW_AGGREGATE_COLUMN_ID
} from "../ps-models/lineitems-store";
import { GridToolbar } from '@mui/x-data-grid';
import { DataGridPro } from '@mui/x-data-grid-pro';
import React, {useMemo, useState} from "react";
import {LineItemEditorClosable} from "./lineItemEditor/LineItemEditor";
import './LineItemsTableView.css';
import {formattedValueAsNumber, inferValueType, normalizeString, ReportMetadata, TimeRange} from "../ps-models";
import {DateRange} from "./DateRange";
import {Button, Icon} from "semantic-ui-react";


export interface LineItemsTableProps  {
  queryResult: QueryResult,
  withGroups?: boolean,
  groupExpansionDepth?: number,
  onRowSelected?: (rowId: string) => void,
  showColumns?: LineItemsColumnFormat
  excludeColumns?: string[],
  lineItemDetailComponent?: (row: { id: string } & any) => JSX.Element
  onUpdated?: (row: any) => void
  onDeleted?: (row: any) => void,
  pinnedLineItems?: string[],
  hideToolbar?: boolean,
  updateSignal?: number,
  showLineItems?: string[],
  excludeLineItems?: string[],
  hideHeaders?: boolean,
  hideFooter?: boolean,
  readonly?: boolean,
  hideLineItems?: boolean,
  projectionsStartDate?: Date,
  highlightNonZeroCells?: boolean,
  leftHeaderColumnSize?: number,
  titleField?: string,
  datePagination?: boolean,
  toolbar?: {
    exportCallback?: () => void
  },
  pageSize?: number

}

export type LineItemsColumnFormat =  Record<string, string | {label: string, format: (metadata: ReportMetadata ) => string}>;

function renderColumnLabel(columnId: string, showColumns?: LineItemsColumnFormat) {

  if(!showColumns) {
    return null;
  }
  if(typeof showColumns[columnId] === 'string') {
    return showColumns[columnId];
  }
  return (showColumns[columnId] as any)?.label;
}

type ColumnsProps = Pick<LineItemsTableProps, 'showColumns' | 'queryResult' | 'excludeColumns' | 'readonly' | 'hideLineItems' | 'projectionsStartDate' | 'highlightNonZeroCells'>;

function queryResultToColumns({showColumns,
                                queryResult,
                                excludeColumns,
                                readonly=true,
                                hideLineItems=false,
                                projectionsStartDate,
                                highlightNonZeroCells
}: ColumnsProps) :{columns: GridColDef[], columnsVisibility: Record<string, boolean>, metadataColumnIds: string[]} {
  showColumns = showColumns && fromPairs(map(([k, v]) => [normalizeString(k), v], Object.entries(showColumns)));
  excludeColumns = excludeColumns && map(normalizeString, excludeColumns);

  const columnById = indexBy( c => c.columnId, queryResult.columns
    .filter(col => !excludeColumns || !excludeColumns.includes(col.columnId))
    .filter(col => {
      if(!hideLineItems && (col.type === 'time' || col.type === 'header')) {
        return true;
      } else if(showColumns && showColumns[col.columnId]) {
        return showColumns[col.columnId]
      } else if(col.type === 'metadata-header') {
        return false;
      }
      return true;
    }));

  let columns: GridColDef[] = queryResult.columns
    .map(col => {
      const headerName = renderColumnLabel(col.columnId, showColumns) || col.text;
      let className = (col.type=== 'time' && projectionsStartDate && ((typeof headerName === "string" ? new Date(headerName).getTime(): headerName) > projectionsStartDate.getTime())) ? ("LineItemsStoreTable projections") : "";
      if(col.columnId === ROW_AGGREGATE_COLUMN_ID){
        className += 'aggregate-column-header'
      }
      return {
        field: col.columnId,
        headerName: headerName,
        resizable: true,
        minWidth: 200,
        headerClassName: className,
        cellClassName: (params) => {
           let classes = '';
           if(highlightNonZeroCells) {
             const valueAsNumber = formattedValueAsNumber(params.value, inferValueType(params.value));
             classes += ( !empty(params.value) && valueAsNumber !== 0 && valueAsNumber !==undefined ? 'highlight-cell' : '')
           }
            return classes;
        },
        editable: !readonly && col.type === 'time',
      }}
    );

  let columnsVisibility =  fromPairs(queryResult.columns.map(c => [
    c.columnId, !!columnById[c.columnId]
  ]));

  let metadataColumnIds = queryResult.columns.filter(c => c.type === 'metadata-header').map(c => c.columnId);

  return {columns, columnsVisibility, metadataColumnIds};
}

export function queryResultToRows(queryResult: QueryResult, showLineItems: string[] | undefined, excludeLineItems: string[] | undefined): any[] {

  showLineItems = showLineItems && map(normalizeString, showLineItems);
  excludeLineItems = excludeLineItems && map(normalizeString, excludeLineItems);


  return queryResult.rows
    .filter(li => !li.hidden)
    .filter(li => !showLineItems || showLineItems.includes(normalizeString(queryResult.rowLineItemName(li))))
    .filter(li => !excludeLineItems || !excludeLineItems.includes(normalizeString(queryResult.rowLineItemName(li))))
    .map((li, index) => {

      let row: Record<string, any> = {
        ...mapObjIndexed((v: ResultCell, k) =>
            v.text,
          filter((c: any) => c.type, li)),
        rowFormatterType: li.rowFormatterType,
        id: li.name.value,
        path: li.path || [li.name.value],
        extraClasses: li.extraClasses
      };

    return row;
  });
}

export function LineItemsTableWithDatePagination(props: LineItemsTableProps) {

  let timeline = props.queryResult.columns.filter(c => c.type === 'time')
    .map(c => (c as TimeColumn).time)

  let [dateRange, setDateRange] =
    useState<TimeRange>({
      start: timeline[0],
     end: timeline[0]
    });

  let newResult = props.queryResult.withTimeRange(dateRange.start, dateRange.end);


  return <div>
    <DateRange

      initialStartDate={new Date(dateRange.start)}
      initialEndDate={new Date(dateRange.end)}
      onSelect={(start, end) => {
      setDateRange({start: start.getTime(), end: end.getTime()});
    }} />
    <LineItemsTable {...props} queryResult={newResult}  />
  </div>

}

export function   LineItemsTable(
  {queryResult, withGroups, onRowSelected, showColumns, excludeColumns, readonly, showLineItems, hideLineItems, projectionsStartDate, highlightNonZeroCells, ...props}: LineItemsTableProps) {


  let {columns, columnsVisibility, metadataColumnIds} = queryResultToColumns({showColumns, queryResult, excludeColumns, readonly, hideLineItems, projectionsStartDate, highlightNonZeroCells });
  let rows = queryResultToRows(queryResult, showLineItems, props.excludeLineItems);

  let rowsPerPage = props?.pageSize ?? 40;
  if(props?.pageSize && [25, 50, 100].includes(props?.pageSize)){
    /*
    * Providing 25, 50 and 100 as values, else you'll see a rows per page selection option which seems like a mui bug.
    * Try providing the same values at this link https://mui.com/x/react-data-grid/pagination/#pagination-model and it also has the same behavior.
    * */
    rowsPerPage+=1;
  }

  let options: Partial<DataGridProProps> = {
    density:'compact',
    getRowClassName: (params: GridRowParams) => `row-${params.row.rowFormatterType} ${params.row.extraClasses ? params.row.extraClasses : ''}`,
    slots:{
      toolbar: GridToolbar
    },
    hideFooter: props.hideFooter,
    defaultGroupingExpansionDepth: withGroups ? props.groupExpansionDepth : 0,
    initialState: {
      pagination: { paginationModel: { pageSize: rowsPerPage } },
      pinnedColumns: { left: [GRID_DETAIL_PANEL_TOGGLE_FIELD,
          'name', ...metadataColumnIds, GRID_TREE_DATA_GROUPING_FIELD], right: [ROW_AGGREGATE_COLUMN_ID]},
      columns: {
        columnVisibilityModel: columnsVisibility
      }
    }
  }

  if(withGroups) {
    options = {
      ...options,
      treeData: true,
      getTreeDataPath: (row: any) => row.path
    };
    columns = columns.slice(1);
  }

  if(props.onUpdated) {
    options = {
      ...options,
      processRowUpdate: (newRow: any) => props.onUpdated!(newRow)
      ,
      onProcessRowUpdateError: (e: any) => {
        console.error("error saving", e);
      }
    }
  }

  if(props.leftHeaderColumnSize) {
    options = {
      ...options,
      groupingColDef: {
        ...options.groupingColDef,
        width: props.leftHeaderColumnSize
      }
    }
  }

  if(props.lineItemDetailComponent) {
    options = {
      ...options,
      getDetailPanelContent: (params: GridRowParams) => {
        return props.lineItemDetailComponent!(params.row);
        //lineItemDetailComponent(params.row);
      },
      getDetailPanelHeight: () => 'auto' // Height based on the content.
    }
  }


  if(props.hideToolbar) {
    options = {
      ...options,
      slots: undefined,
    }
  }

  if(props.toolbar) {
    options = {
      ...options,
      slots: {
        toolbar: CustomToolbar(props.toolbar)
      }
    }
  }

  return <DataGridWrapper
    rows={rows}
    columns={columns}
    options={options}
    onRowSelected={onRowSelected}
    updateSignal={props.updateSignal}
    hideHeader={props.hideHeaders}
  />
}


const CustomToolbar = (options: LineItemsTableProps['toolbar'] = {}) => () => {


  return (
    <GridToolbarContainer>
      <GridToolbarColumnsButton />
      <GridToolbarDensitySelector />
      {!options?.exportCallback && <GridToolbarExport />}
      {options?.exportCallback && <button
      onClick={options.exportCallback}
      style={{border: "none", background: "none", cursor: "pointer", color: "#1976D2"}}
      className="MuiButtonBase-root MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall MuiButton-root MuiButton-text MuiButton-textPrimary MuiButton-sizeSmall MuiButton-textSizeSmall">
          <Icon name='download' />EXPORT
      </button>}
      <Box sx={{ flexGrow: 1 }} />
      <GridToolbarQuickFilter />
   </GridToolbarContainer>
  );
};

function DataGridWrapper({rows, updateSignal, columns, options, onRowSelected, hideHeader}: {
  rows: any[], columns: GridColDef[], options: Partial<DataGridProProps>, hideHeader?: boolean,
  onRowSelected?: (rowId: string) => void, updateSignal?: number}) {

  console.info('DataGridWrapper', options.initialState);

  return useMemo(()=> {
    return <div style={{width: '100%'}}  className={"LineItemsStoreTable " + (hideHeader ? 'hidden-header': '') }  >
    <DataGridPro
      {...options}

      {...(!options?.initialState ? {
      initialState: {
        pagination: {paginationModel: {pageSize: 40}},
        pinnedColumns: {left: [GRID_DETAIL_PANEL_TOGGLE_FIELD, 'name', GRID_TREE_DATA_GROUPING_FIELD]}
      }}:{} )}
      rows={rows}
      columns={columns}
      columnBuffer={2} columnThreshold={2}
      autoHeight
      pagination={rows.length>1}
       editMode={'row'}
      groupingColDef={{
        minWidth: 300,
        hideDescendantCount: true,
        valueGetter(params) {
          return params.row.name;
        },
        ...options.groupingColDef
      }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
          quickFilterProps: { debounceMs: 500 },
          csvOptions: {
            escapeFormulas: false,
          }
        }
      }}

      onRowSelectionModelChange={(e) => { onRowSelected && onRowSelected(e[0]?.toString() || ""); }}

    /></div>},
    updateSignal ? [updateSignal] : [rows, columns]
  );
}

export function LineItemsTableWithFormulas({store, ...tableProps}:{store: LineItemsStore} & LineItemsTableProps) {
  let [selectedLi, setSelectedLi] = useState<string | undefined>();
  let {queryResult, onRowSelected} = tableProps;

  let changeIf = JSON.stringify(tableProps);

  let table = useMemo(() => {
      return     <LineItemsTable
        {...tableProps}
        onRowSelected={(row) => {
          setSelectedLi("")
          setSelectedLi(row as string);
          onRowSelected && onRowSelected(row)
        }}
      />
  }, [changeIf]);

  return <div>
    <div className="LineItemsStoreTable" >
      {table}
    </div>
    {selectedLi && <LineItemEditorClosable
      store={store}
      lineItemName={selectedLi}
    />}

  </div>
}

