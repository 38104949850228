import React, {useEffect, useState} from "react";
import {Button, Dimmer, Header, Input, Loader, Message, Segment, Select, TextArea} from "semantic-ui-react";
import {useHistory} from "react-router-dom";
import {useCompany, useCompanyId, usePlatformUrls} from "../../core";
import {PsFileUploader} from "../../ui/FileUploader.component";
import {ProjectStoreDetails} from "./ProjectStoreDetails";
import {createAmProjectVersion, getAmProject} from "./amProject.client";
import {AmProject} from "../../ps-types";
import {Loading, LoadingBlock, SmallLoading} from "../../ui/Loading";
import {LineItemsStore} from "../../ps-models/lineitems-store";
import {EditorParam, MultiParameterEditor} from "./MultiParameterEditor";
import {mapObjIndexed, values} from "ramda";
import {convertToNumberOrString} from "../../ps-models";
import {LineItemsFieldSet} from "../../ps-models/line-items";
import {usePopup} from "../../ui/popup/Popup";
import {useMessages} from "../../ui/MessagesProvider";
import {ErrorPopup} from "../../ui/popup/ErrorPopup";
import {ContentPopup} from "../../ui/popup/ContentPopup";
import {authStorage} from "../../auth";
import {renderLogsContainer} from "../../ui/ProcessLogs";
import {useInstantUpdater} from "../../generic.hooks";
import {GoBackButton} from "../../ui/GoBackButton";
const {Grid, Card} = require("semantic-ui-react");

export function ProjectTemplate({projectId}: {projectId: string}) {
  let companyId = useCompanyId();
  let [project, setProject] = useState<AmProject>();
  let [ title, setTitle ] = useState("");
  let [ description, setDescription ] = useState("");
  const history = useHistory();
  const { amProjectDetails } = usePlatformUrls();
  let {clear, error} = useMessages();
  const amProjectId = useCompany().amProjectId;
  const [template, setTemplate] = useState<string>(amProjectId || "")
  let [params, setParams] = useState<Record<string, EditorParam>>({});
  let {openPopup, closePopup}   = usePopup();
  let [uploadProgress, setUploadProgress] = useState<number>(0);
  const [fileUploaderReRenderer, updateFileUploaderReRenderer] = useInstantUpdater()
  const [processing, setProcessing] = useState<boolean>(false);
  const currentUser =  authStorage.getUser();
  const isAdmin = currentUser.isAdmin()

  useEffect(() => {
    getAmProject(companyId, projectId).then((res) => {
      setProject(res)
      //Set default title for version
      setTitle(`v${res.versions.length + 1}`)
    });
  }, []);

  if(!project) return <LoadingBlock message="Loading Project..." />

  const handleApply = (store: LineItemsStore) => {
    clear();
    values(params).forEach((param) => {
      store!.getDataSet().addParam(
        param.name,
        convertToNumberOrString(param.value),
        LineItemsFieldSet.fromMap(
          {'projectParam': true}
        )
      );
    });
    const isValid = isDescriptionValid(description);
    if(!isValid){
      return;
    }
    setProcessing(true);
     createAmProjectVersion(
      companyId,
      projectId,
      title,
      description,
      null,
      template,
      store
    ).then((res) => {
       setProcessing(false);
       showMappingErrors({...res}, openPopup, closePopup, updateFileUploaderReRenderer, continueToProject); // what happens if both errors and warnings co-exist. We show both...
       history.push(amProjectDetails(projectId));
     })
    .catch((err) => {
      setProcessing(false);
      console.error(err);
      openPopup(<ErrorPopup e={err} header='Failed to create version' onClose={closePopup} />);
    });
  }

  const isDescriptionValid = (suppliedDescription: string)=>{
    let isValid = true;
    if(!suppliedDescription){
      error(`Please provide a brief description of the version`);
      setProcessing(false);
      isValid = false;
    }
    return isValid;
  }

  const fileUploadCallback = async (file?: File ) => {
    clear();
    if(!file) return;
    const isValid = isDescriptionValid(description);
    if(!isValid){
      return;
    }
    setProcessing(true);
    await createAmProjectVersion(
      companyId,
      projectId,
      title,
      description,
      file,
      template,
      null,
      mapObjIndexed((param) => convertToNumberOrString(param.value), params),
      (progress) => setUploadProgress(progress)
    ).then((res) => {
      setProcessing(false);
      showMappingErrors({...res}, openPopup, closePopup, updateFileUploaderReRenderer, continueToProject);
      if(!('errors' in res && res.errors.length>0) && res.warnings.length===0){
        history.push(amProjectDetails(projectId));
      }
    })
    .catch((err) => {
      setProcessing(false);
      updateFileUploaderReRenderer();
      console.error(err);
      openPopup(<ErrorPopup e={err} header='Failed to create version' onClose={closePopup} />);
    });
  }

  const continueToProject = () => {
    history.push(amProjectDetails(projectId));
  }

  let options;
  if(amProjectId === "FourthPartner") {
    options = [
      {key: "FourthPartner", value: "FourthPartner", text: "Project"},
      {key: "FourthPartner", value: "FourthPartner-SPV", text: "SPV"}
    ]
  }

  const handleParamsUpdate = (updatedParams: Record<string, EditorParam>, deleted: string[]) => {
    setParams(updatedParams);
  }

  return (<>
        {processing && <Loading  />}
        <Segment style={{padding: "40px"}}>
        <div>
        <div style={{ position: "absolute", top: 10, left: 10 }}>
          <GoBackButton />
        </div>
          <Header as="h2">{project?.name}</Header>
          <p>
            <Input label="Version Title" placeholder="Version Title"
                   value={title}
                   required
                   readOnly
                   onChange={(e, data)=> setTitle(data.value) } />
            <Input label="Version Description" maxLength={100} style={{ width: `${Math.max(30, description.length + 12)}ch` }} placeholder="Version Description" onChange={(e, data)=> setDescription(data.value) } />
            <MultiParameterEditor
                params={params} onUpdate={handleParamsUpdate} />
          </p>
          <Grid>
            <Grid.Row columns={1} >
              <h3>Create From File Template</h3>
              <Grid.Column >
                  {options &&
                    <Select style={{marginBottom: "10px"}}
                    onChange={(e, data) => setTemplate(data.value as string)}
                    options={options} placeholder='Select Template' />}
                    <PsFileUploader
                    uploadProgress={uploadProgress}
                    setFile={fileUploadCallback}
                    updater={fileUploaderReRenderer}
                    /> 
              </Grid.Column>

            </Grid.Row>

            {isAdmin && <Grid.Row columns={1} >
              <Grid.Column>
                <hr />
                <h3>Create Empty</h3>
                <ProjectStoreDetails onApply={handleApply} />
              </Grid.Column>
            </Grid.Row>}
          </Grid>

        </div>
        </Segment>
  </>
  );
}

// @TODO: This is a quick extraction from showMappingErrors. Reduce duplicate code down the line.
export const ProjectVersionMappingLogs = ({messages} :{messages:{errors?: string[], warnings: string[]}})=>{
  const {errors: mappingErrors, warnings: mappingWarnings} = messages;
  const hasErrors = (mappingErrors?? []).length > 0;
  const hasWarnings = mappingWarnings.length > 0;
  if(hasErrors || hasWarnings){
    return <Grid>
        <Grid.Row columns={hasErrors ? 2:1}>
          {hasErrors && <Grid.Column width={8}>
            {renderLogsContainer(mappingErrors ?? [], 'error', '400px')}
          </Grid.Column>}
          <Grid.Column width={hasErrors ? 8: 16}>
            {renderLogsContainer(mappingWarnings, 'warning', '400px')}
          </Grid.Column>
        </Grid.Row>
      </Grid>
  }
  return <></>
}

export const showMappingErrors = (
  messages: { errors?: string[]; warnings: string[] },
  openPopup: (element: JSX.Element) => void,
  closePopup: () => void,
  updateFileUploaderReRenderer?: () => void,
  continueToProject?: () => void
) => {
  const { errors: mappingErrors, warnings: mappingWarnings } = messages;
  const hasErrors = (mappingErrors ?? []).length > 0;
  const hasWarnings = mappingWarnings.length > 0;
  const popUpContentHeader = hasErrors
    ? "Version Creation Failed because the following problems were encountered while parsing the supplied excel"
    : "The version was created but with the following warnings";
  if (hasErrors && updateFileUploaderReRenderer) {
    updateFileUploaderReRenderer();
  }
  if (hasErrors || hasWarnings) {
    openPopup(
      <ContentPopup
        header={""}
        size={"small"}
        onClose={closePopup}
        closeOnEscape={false}
        closeOnDimmerClick={false}
      >
        <Header size="small">{popUpContentHeader}</Header>
        <>
          <ProjectVersionMappingLogs messages={messages} />
          {hasErrors && (
            <>
              <Button onClick={() => closePopup()}>Re-Upload</Button>
            </>
          )}
          {continueToProject && <Button
            floated={hasErrors ? "right" : "left"}
            onClick={() => continueToProject()}
          >
            Go Back To Project
          </Button>}
        </>
      </ContentPopup>
    );
  }
};