import {DefaultModule, registerModule} from "../../builder/DashboardConfigServiceRegistry";
import {LineItemsStore} from "../../../ps-models/lineitems-store";


registerModule('Ampyr', new class extends DefaultModule {
    onStoreInit(store: LineItemsStore, dashboardId: string) {
        let items = store.getDataSet().getLineItemsWithField('store_sourceId')

        items.forEach(item => {
            item.fields.addDynamicField("Phase",
              /*language=JavaScript*/
              `
                  let con = 'Construction';
                  let pct = 'Post Contract Term';
                  let conAct = 'Construction Active';
                  let op = 'Operation';
                  let pa = 'Project Active';
                  
                  let conStartTime = timeOfFirstValue(con);

                  let projectActiveStartTime = timeOfFirstValue(pa);
                  let projectActiveEndTime = timeOfLastValue(pa);
                  let currentTimeInMs = new Date().getTime();  
                  let isCon = lit(con, new Date());
                  let isPct = lit(pct, new Date());
                  let isContActive = lit(conAct, new Date());
                  let isOp = lit(op, new Date());
                  
                  
                  
                  if(!isCon && !isPct && !isOp && !isContActive && projectActiveStartTime > currentTimeInMs){
                      return 'Committed';
                  }
                  if(projectActiveEndTime && currentTimeInMs > projectActiveEndTime){
                      return 'Lapsed';
                  }
                  if(isCon) {
                      return 'Construction';
                  }
                  if(isPct) {
                      return 'Post Contract Term';
                  }
                  if(isOp) {
                      return 'Operation';
                  }
                  if(conStartTime && conStartTime > new Date().getTime()) {
                      return 'Development';
                  }
                  
                  return 'Unknown';
              `)
        });
        // @TODO: Move it back at the storeMapping of Ampyr, when doing the next model mapping.
        if(dashboardId === '265b6'){ // Only for the cashflow rollup dashboard
            let itemsOfRevenueSection = store.getDataSet().getByField('section', 'Revenue');
            itemsOfRevenueSection.forEach((item)=>{
                item.fields.addField('section', 'Cash Inflow')
            })

            let itemsOfExpensesSection = store.getDataSet().getByField('section', 'Expenses');
            itemsOfExpensesSection.forEach((item)=>{
                item.fields.addField('section', 'Cash Outflow')
            })
        }


        //store.getExecution().processDynamicFields();

    }
})