import { Button, Grid } from "semantic-ui-react";
import { CreateEntity } from "../../ui/crud/CreateEntity.component";
import { UpdateEntity } from "../../ui/crud/UpdateEntity.component";
import { buildForm } from "../../ui/crud/common/Field";
import { useEffect, useState } from "react";
import { compareNormalized } from "../../ps-models";
import { useMessages } from "../../ui/MessagesProvider";
import { useDashboardService } from "../builder/DashboardConfigService";
import { AmScenario, extractProjectAndVersionIdFromStoreId, getStoreId } from "../../ps-types";
import { saveScenario } from "./scenarios.client";
import { useCompanyId } from "../../core";
import { keys } from "ramda";
import { ScenarioContext } from "./ScenarioFilter.component";

export function ScenarioActions({
  scenarioTitle,
  projectOptions,
  scenarios,
  selectedContextInternal,
  currentParamsMap,
  currentFiltersMap,
  selectedScenario,
  initialFiltersMap,
  setSelectedScenario,
  setScenarioTitle,
  update,
  setSelectedContextInternal,
  actionButtonDisable,
  setActionButtonDisable,
  projectVersionInitialSelections
}: {
  scenarioTitle: string;
  projectOptions: any;
  scenarios: Array<any>;
  selectedContextInternal: any;
  currentParamsMap: AmScenario["paramsMap"];
  currentFiltersMap: AmScenario["filtersMap"];
  initialFiltersMap: AmScenario["filtersMap"];
  selectedScenario: string;
  setSelectedScenario: (string: string) => void;
  setScenarioTitle: (string: string) => void;
  update: () => void;
  setSelectedContextInternal: (arg: ScenarioContext) => void;
  actionButtonDisable: boolean;
  setActionButtonDisable: (boolean: boolean) => void;
  projectVersionInitialSelections: any[];
}) {
  const { error } = useMessages();
  const { getService } = useDashboardService();
  const dbService = getService();
  const companyId = useCompanyId();

  const [saveButtonDisableBoolean, setSaveButtonDisableBoolean] =
    useState<boolean>(true);
  const [clearButtonDisbaled, setClearButtonDisabled] = useState<boolean>(true);

  const clearFilters = () => {
    setSaveButtonDisableBoolean(true);
    setActionButtonDisable(true);
    if (dbService.getConfig().isProjectVersionSelectorExposed()) {
      setSelectedContextInternal({
        storeIds: projectVersionInitialSelections,
        paramsMap: undefined,
        filterDetails: undefined,
      });
    }
    dbService.updateGlobalContext({
      paramsMap: undefined,
      storeIds: [],
      filterDetails: {
        filterQuery: undefined,
        filtersMap: {},
        selectedFilters: {},
      },
    });
    // TODO: IMPORTANT NOTE: PENDING havingParameterValues values
    dbService.getConfig().setQuery(
      dbService.getConfig().getQuery().selectsFirstParamValues(dbService.getConfig().getFilters()
        .filter(f => f.required)
        .map(f => f.fieldName)
      ).havingParameterValues({...dbService.getGlobalContext()?.filterDetails?.filtersMap}))
    setSelectedScenario("");
    setScenarioTitle("Latest Versions");
  };

  const handleSaveScenario = (
    scenario: { name: string },
    scenarioTitle: string
  ) => {
    if (!scenario || !Object.keys(scenario).length) {
      scenario = {
        name: scenarioTitle,
      };
    }
    if (!projectOptions) return;
    if (scenario.name !== scenarioTitle) {
      //Check scenarios have different names and selection.
      if (scenarios.find((s) => compareNormalized(s.name, scenario.name))) {
        error("Scenario with the same name or selection already exists");
        return;
      }
    }

    for (let i = 0; i < scenarios.length; i++) {
      let selected = scenarios[i].selectedProjectVersions
        .map((s: any) => getStoreId(s.projectId, s.versionId))
        .sort()
        .join(",");
      let newSelected = selectedContextInternal.storeIds.sort().join(",");

      if (
        selected === newSelected &&
        JSON.stringify(scenarios[i].paramsMap ?? {}) ===
          JSON.stringify(currentParamsMap ?? {}) &&
        JSON.stringify(scenarios[i].filtersMap ?? {}) ===
          JSON.stringify(currentFiltersMap ?? {})
      ) {
        error(
          `Scenario ${scenarios[i].name} has the same selection and set of inputs(if provided)`
        );
        return;
      }
    }

    saveScenario(companyId, {
      name: scenario.name,
      selectedProjectVersions: selectedContextInternal.storeIds.map((s: any) =>
        extractProjectAndVersionIdFromStoreId(s)
      ),
      paramsMap: currentParamsMap,
      filtersMap: currentFiltersMap,
    }).then((res) => {
      setSelectedScenario(res.id);
      setScenarioTitle(scenario.name);
      update();
    });
  };

  useEffect(() => {
    let ifFilterSelected = true;
    if (currentFiltersMap) {
      ifFilterSelected = keys(currentFiltersMap).every(
        (k) => currentFiltersMap[k].length === 0
      );
    }
    setClearButtonDisabled(
      ifFilterSelected && actionButtonDisable && !selectedScenario
    );
  }, [currentFiltersMap, actionButtonDisable, selectedScenario]);
  useEffect(() => {
    if (
      currentFiltersMap &&
      Object.keys(currentFiltersMap).length &&
      JSON.stringify(currentFiltersMap) !== JSON.stringify(initialFiltersMap)
    ) {
      setSaveButtonDisableBoolean(false);
    } else {
      setSaveButtonDisableBoolean(true);
    }
  }, [currentFiltersMap, selectedScenario]);

  return (
    <Grid.Column floated="right" width={4}>
      {dbService.getConfig().isProjectVersionSelectorExposed() && (
        <div style={{ float: "right", marginLeft: "2px" }}>
          <SaveScenario
            disable={saveButtonDisableBoolean && actionButtonDisable}
            scenarioTitle={scenarioTitle}
            onSaving={(scenario) => handleSaveScenario(scenario, scenarioTitle)}
          />
        </div>
      )}
      {/* <Button size="tiny" content="Apply" floated="right" color="black" /> */}
      <Button
        size="tiny"
        floated="right"
        content="Clear"
        onClick={clearFilters}
        disabled={clearButtonDisbaled}
      />
    </Grid.Column>
  );
}

function SaveScenario({
  onSaving,
  disable,
  scenarioTitle,
}: {
  disable: boolean;
  scenarioTitle: string;
  onSaving: (scenario: { name: string }) => void;
}) {
  const formDef = buildForm(
    {
      entityName: "Scenario",
      options: {
        buttonText: "Save",
        modalTitle: "Save Scenario",
        buttonDisabled: disable,
      },
      createEntity: async (formData: { name: string }) => {
        onSaving(formData);
      },
    },
    {
      name: {
        type: "text",
        label: "Name",
        defaultValue: scenarioTitle,
      },
    }
  );
  return <CreateEntity {...formDef} />;
}
